<template>

    <div class="w-2/3  px-4 flex flex-col py-2 relative ">

        <loader :loading="loading || oportunitiesLoading" />

        <div class="scroll-gradient"></div>

        <div class="flex py-2 -mx-2 items-center">
            <div class="h-full w-1/4 rounded-lg bg-body my-2">
                <input type="text" placeholder="Search" v-model="searchInput" class="w-full h-full rounded text-lg
                    bg-body flex flex-row justify-start items-center px-2">
            </div>
            <div class="h-full w-3/4 flex flex-row justify-start">
                <template v-if="OpportunitiesFilterList && OpportunitiesFilterList.filters">
                    <div class="w-full px-2 flex items-center relative">
                        <loader :loading="loadingSMU" />
                        <div class="text-font-gray mr-2">SMU</div>
                        <!-- <multiselect v-model="smuSelected" :multiple="true" :searchable="true" @search-change="asyncFind($event, 'competencyIdWithRegionId')"
                                     :options="optionsSMU" :disabled="ifdisabledSMU"
                                     label="filter_name"
                                     trackBy="filter_id"/> -->
                        <multiselect v-model="smuSelected" :multiple="true" :searchable="true"
                                     :options="OpportunitiesFilterList.filters.competency" :disabled="ifdisabledSMU"
                                     label="filter_name"
                                     trackBy="filter_id"/>
                    </div>
                    <div class="w-full px-2 flex items-center">
                        <div class="text-font-gray mr-2">Status</div>
                        <multiselect v-model="statusFilter" :multiple="true"
                                     :options="OpportunitiesFilterList.filters.status"
                                     label="filter_name"
                                     trackBy="filter_id" />
                    </div>
                    <div class="w-full px-2 flex items-center">
                        <div class="text-font-gray mr-2">Progress</div>
                        <multiselect v-model="probavilityFilter" :multiple="true"
                            :options="OpportunitiesFilterList.filters.probability"
                            label="filter_name"
                            trackBy="filter_id"/>
                    </div>
                    <div class="w-full px-2 flex items-center">
                        <div class="text-font-gray mr-2">Sector</div>
                        <multiselect v-model="sectorSelected" :disabled="ifdisabledSector" :multiple="true"
                            :options="optionsSector"
                            label="filter_name"
                            trackBy="filter_id"/>
                    </div>
                </template>
            </div>
        </div>

        <div class="flex items-center my-4">
            <template v-if="OpportunitiesList && OpportunitiesList.meta">
                <div class="text-xl mr-2 font-semibold">
                    {{ OpportunitiesList.meta.to }} / {{ OpportunitiesList.meta.total }}
                </div>
                <div class="text-font-gray">Opportunities</div>
            </template>
            <template v-if="OpportunitiesFilterList.total_sales">
                <div class="text-font-gray ml-auto mr-2">Sales</div>
                <div class="text-xl mr-2 font-semibold text-aux">
                    {{ OpportunitiesFilterList.total_sales | reduceBigNumbers(2) }} €
                </div>
            </template>
        </div>

        <div class="w-full h-full overflow-hidden relative">

            <transition-group name="fade" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in" class="flex overflow-auto flex-wrap content-start h-full grid grid-cols-3 gap-4" tag="div">

                <template v-if="!oportunitiesLoading && !loading">

                    <div class="box flex flex-col cursor-pointer" v-for="(opportunity, opportunities) in (OpportunitiesList.data)" :key="`${opportunities}index`" @click="selectedOportunity = opportunity">
                        <OpportunityDesktop :opportunity="opportunity" :sectors:="sectors"/>
                    </div>

                </template>

                <div v-else>
                    <loader :loading="oportunitiesLoading" />
                </div>

            </transition-group>

        </div>

        <div v-if="OpportunitiesList.meta && OpportunitiesList.meta.last_page > OpportunitiesList.meta.current_page" class="btn-gray" @click="page++">MORE</div>

        <div v-if="selectedOportunity" class="absolute h-full w-full top-0 left-0 flex flex-col justify-center items-center">

            <div class="absolute h-full w-full bg-module-bottom-10 z-10 bg-module-bottom_90" style="filter: blur(4px);"></div>

            <div class="h-auto w-1/2 rounded-lg bg-module-top shadow-md overflow-hidden p-2 z-30 relative">

                <loader :loading="loaderComment"></loader>

                <div class="h-auto flex flex-row justify-start items-center">

                    <div class="h-full flex w-11/12 flex-none">
                        <div class="text-warning text-lg">
                            {{ selectedOportunity.probability }}%
                        </div>
                        <div class="ml-2">
                            {{ selectedOportunity.opportunity_name }}
                        </div>
                    </div>

                    <div class="h-8 w-8 flex-none rounded-full flex flex-col justify-center items-center ml-auto cursor-pointer hover:bg-aux-15" @click="selectedOportunity = false">
                        <i class="mdi mdi-close text-lg"></i>
                    </div>

                </div>

                <div class="flex-1 min-h-0 p-2 flex flex-col">

                    <div class="h-auto">


                        <div class="flex items-center mb-1 mt-auto">
                            <div class="ellipsis w-full text-font-gray font-semibold text-tiny mr-2 ellipsis">
                                {{ selectedOportunity.client_name }}
                            </div>
                            <div class="flex-none text-aux font-semibold">
                                {{ selectedOportunity.competency || 'N/A' }}
                            </div>
                        </div>
                        <div class="flex items-center mb-2 ">
                            <div class="text-font-gray font-semibold text-tiny lowercase ellipsis">
                                {{ selectedOportunity.ep_name }}
                            </div>
                            <div class="ml-auto text-thin font-semibold"></div>
                            <div class="rounded-full px-2 text-box text-xs ml-2 font-semibold"
                                :style="'color:' + legendColors[selectedOportunity.sector_ambiciona] +
                            ';background-color:' + legendColors[selectedOportunity.sector_ambiciona] + '22'">
                                {{ selectedOportunity.sector_ambiciona }}
                            </div>
                        </div>
                        <div class="flex items-center mb-2" v-if="false">
                            <div class="text-font-gray font-semibold text-tiny">Sector</div>
                        </div>
                        <div class="flex items-center">
                            <div class="text-font-gray mr-2">Opportunity Value</div>
                            <div class="text-xl font-semibold mr-auto">
                                {{ selectedOportunity.valor_precio | numberFormat() }}€
                            </div>
                            <div class="text-font-gray mr-2">Margin</div>
                            <div class="text-xl font-semibold">
                                {{ selectedOportunity.margen | numberFormat(2) }}%
                            </div>
                        </div>

                    </div>

                    <div class="h-96 mt-2 bg-module-bottom p-2 rounded-lg overflow-auto mobile-scrollbar flex-col-reverse">

                        <transition-group v-if="commentsOpportuniti && !loaderCommentsOpportuniti" class="min-h-full flex flex-col justify-end " name="fadeAndSlide" tag="div">

                            <div :key="index" v-for="(post,index) in commentsOpportuniti" class="">

                                <div  class=" mt-2">

                                    <div class="box mb-2 py-2 relative flex flex-col" style="min-height: 9rem;">

                                        <div class="flex items-center mb-2">

                                            <div class="h-14 flex flex-col justify-between items-start">

                                                <span class="text-beige text-xs">
                                                    {{ [post.date, 'YYYY-MM-DD'] | moment('DD / MM / YYYY') }}
                                                </span>

                                                <span class="text-xs text-font-light font-thin uppercase">{{ post.commentable_type }}</span>

                                                <span class="font-semibold text-sm">{{ post.commentable_name }}</span>

                                            </div>

                                            <div class="h-auto absolute top-0 right-0 flex flex-row mt-2 mr-2">

                                                <div class="ml-auto header-btn-gray" @click="selectedEdit = post.id">
                                                    <i class="mdi mdi-pencil-outline" ></i>
                                                </div>

                                                <div class="header-btn-gray" @click="selectedDelete = post.id">
                                                    <i class="mdi mdi-trash-can-outline" ></i>
                                                </div>

                                            </div>

                                        </div>



                                        <div class="mb-2  text-font-light font-thin">{{ post.commentary }}</div>

                                        <div class="flex flex-row justify-end items-center mt-auto">

                                            <el-tooltip class="item" effect="dark" :content="post.user_name" placement="top">

                                                <div v-if="post.image" class="h-6 w-6 rounded-full" :style="'background-image: url(' + post.image + '); background-repeat: no-repeat; background-attachment: fixed; background-position: center;'"></div>

                                                <div v-else class="w-auto px-2 flex flex-col justify-center items-center">
                                                    <vue-initials-img :name="post.user_name" class="rounded-full h-6"/>
                                                </div>

                                            </el-tooltip>

                                        </div>

                                        <transition  name="listSubCats">
                                            <div class="absolute h-full w-full bg-gradient-module top-0 left-0 rounded-lg overflow-hidden"
                                                v-if="selectedDelete === post.id">
                                                <div class="header-btn-gray absolute right-0 mr-2 top-0 mt-2 z-40" @click="selectedDelete = false">
                                                    <i class="mdi mdi-close" ></i>
                                                </div>
                                                <div class="center-absolute">
                                                    <loader :loading="loadingDelete"/>
                                                    <div class="mb-2">Are you Sure?</div>
                                                    <div>
                                                        <div @click="deleteComment(post.id)" class="btn-danger inline-block mr-2">Delete</div>
                                                        <div @click="selectedDelete = false" class="btn-gray inline-block">Cancel</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </transition>

                                        <transition  name="listSubCats">
                                            <div class="absolute h-full w-full bg-gradient-module top-0 left-0 rounded-lg overflow-hidden"
                                                v-if="selectedEdit === post.id">

                                                <div class="header-btn-gray absolute right-0 mr-2 top-0 mt-2 z-40" @click="selectedEdit = false">
                                                    <i class="mdi mdi-close" ></i>
                                                </div>
                                                <div class="center-absolute p-4 flex items-end">

                                                    <textarea class="border bg-gradient-module w-full mr-2 h-24 outline-none p-2 rounded-lg shadow-lg"
                                                        placeholder="Send message..." v-model="post.commentary"></textarea>
                                                    <div class="btn-aux inline-block" @click="editComment(post)">
                                                        <i class="mdi mdi-send"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </transition>

                                    </div>

                                </div>

                            </div>

                        </transition-group>

                        <div v-else class="h-full relative">

                            <loader :loading="loaderCommentsOpportuniti" />

                        </div>

                    </div>

                    <div class="h-12 mt-2 flex flex-row">

                        <div class="h-full w-5/6">

                            <textarea class="border bg-gradient-module w-full h-full outline-none p-2 rounded-lg shadow-lg"  placeholder="Send message..." v-model="commentary"></textarea>

                        </div>

                        <div class="h-full w-1/6 px-1">

                            <div class="btn-aux h-full w-full inline-block flex flex-row justify-center items-center" @click="publishComment()">
                                <i class="mdi mdi-send"></i>
                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>

</template>

<script>

    import { state,actions } from '@/store';
    import axios from 'axios'
    import Loader from '../../Globals/Loader.vue';
    const OpportunityDesktop = () => import('@/components/Desktop/Pipeline/OpportunityList/Opportunity');
    const CancelTokenPipeline = axios.CancelToken;
    let cancel;


    export default {
        components: {
            OpportunityDesktop,
            Loader
        },
        data() {
            return {
                OpportunitiesList: {},
                OpportunitiesFilterList: {},
                sectors: {},
                probavilityFilter: [],
                statusFilter: [],
                itemsPerPage: 60,
                smuFilter: [],
                page: 1,
                numberOfOpportunities: 0,
                loading: false,
                query: "",
                results: "",
                searchInput: '',
                sectorFilter: false,
                filters_probability: [],
                selectedOportunity:false,
                oportunitiesLoading: false,
                commentary: '',
                loaderComment:false,
                loaderCommentsOpportuniti: false,
                commentsOpportuniti:false,
                selectedEdit:false,
                selectedDelete:false,
                optionsSMU:[],
                smuSelected:[],
                sectorSelected:[],
                optionsSector:[],
                loadingSMU:false
            }
        },
        methods: {
            clear() {
                this.loading = false;
                this.results = "";
                this.query = "";
            },
            handleSearch: _.debounce(function() {
               this.preApiCall();
            }, 300),
            preApiCall() {
                if (cancel !== undefined) {
                    cancel();
                }
                this.query = {
                    ...this.call_params,
                    page: this.page
                }
                this.getOpportunitiesList(this.query);
            },
            getServiceLine() {
                this.loading = true;
                this.axios.get('pipeline/service_line', { params: this.params })
                    .then(response => {
                        this.sectors = response.data.data.sectors
                    })
            },
            getOpportunitiesList(query) {

                this.loading = true;
                this.oportunitiesLoading = true

                if (query !== "") {
                    this.axios({
                        method: "get",
                        url: "pipeline/opportunities",
                        cancelToken: new CancelTokenPipeline(function executor(c) {
                            cancel = c;
                        }),
                        params: { ...query }
                    }).then(response => {
                        if (this.page === 1) {
                            this.OpportunitiesList = response.data
                        }
                        else {
                            this.OpportunitiesList.meta = response.data.meta;
                            for (let index = 0; index < response.data.data.length; index++) {
                                this.OpportunitiesList.data.push(response.data.data[index])
                            }
                        }
                        this.loading = false
                        this.oportunitiesLoading = false
                    }).catch(err => {
                        this.loading = false
                        this.oportunitiesLoading = false
                        this.results = err.message;
                    })
                }
                else {
                    this.clear()
                }
            },
            getFilterOpportunities() {
                this.axios.get('pipeline/opportunities_filters', { params: {...this.oppParams, page: this.page} })
                    .then(response => {
                        this.OpportunitiesFilterList = response.data
                        this.loading = false
                        // this.smuFiltered();
                        this.sectorFiltered();
                        // this.getSMU();
                })
            },
            publishComment(){

                this.loaderComment = true

                let mess = {
                    // ...this.params,
                    commentary: this.commentary,
                    project_id: this.selectedOportunity.opportunity_id,
                }

                this.axios.post('commentary/opportunities/' + this.selectedOportunity.opportunity_id + '/commentaries', mess)
                .then(response => {
                    this.loaderComment = false
                    this.commentary = ''
                    actions.setCommentsListen(!state.commentsListen)
                    // this.selectedOportunity = false
                    this.getComments(this.selectedOportunity)
                })

            },
            editComment(post){

                let mess = {
                    ...this.params,
                    ...post
                }

                this.loading = true

                this.axios.post('commentary/commentaries/'+ post.id +'/update', mess).
                then(response => {
                    this.loading = false
                    this.selectedEdit = false;
                    this.getComments(this.selectedOportunity);
                })

            },
            deleteComment(messageId) {
                // this.loading = true
                this.loadingDelete = true
                let mess = {
                    ...this.params,
                    ...{ id: messageId }
                }
                this.axios.post('commentary/commentaries/'+ messageId +'/delete', mess).
                then(response => {
                    this.getComments(this.selectedOportunity)
                })
                // this.loading = false
                this.loadingDelete = false
            },
            getComments(n){
                this.loaderCommentsOpportuniti = true;

                this.axios.get('commentary/opportunities/' + n.opportunity_id + '/commentaries', { params: this.topParams})
                .then(response => {

                    this.response = response.data
                    this.commentsOpportuniti = response.data.data.length ? response.data.data.reverse() : []
                    // this.commentsOpportuniti = response.data.data
                    this.loaderCommentsOpportuniti = false

                })
            },
            getSMU(){

                let result = [];
                let smu = state.globalParams.competency

                if(smu) {
                    for (let index = 0; index < this.optionsSMU.length; index++) {
                        for (let x = 0; x < smu.length; x++) {
                            if(this.optionsSMU[index].filter_id === smu[x]){
                                this.smuSelected.push(this.optionsSMU[index])
                            }
                        }

                    }
                }

            },
            getSector(){

                let result = [];
                let sector = state.globalParams.sector

                if(sector) {
                    for (let index = 0; index < this.optionsSector.length; index++) {
                        for (let x = 0; x < sector.length; x++) {
                            if(this.optionsSector[index].filter_id === sector[x]){
                                this.sectorSelected.push(this.optionsSector[index])
                            }
                        }
                    }
                }

            },
            smuFiltered(){

                this.axios.get('filters/competencyIdWithRegionId', {
                    params: this.params
                },).then(response => {
                    this.optionsSMU = response.data.data
                    this.getSMU();
                })

            },
            sectorFiltered(){

                this.axios.get('filters/sector', {
                    params: this.params
                }).then(response => {
                    this.optionsSector = response.data.data
                    this.getSector();
                })

            },
            handleSearch: _.debounce(function() {
                this.asyncFind();
            },300),
            asyncFind (query, id) {
                if (cancel !== undefined) {
                    cancel();
                }
                this.searchFilter(id, query)
            },
            searchFilter(j, search) {

                this.loadingSMU = true;

                this.axios({
                    method:"get",
                    url: '/filters/competencyIdWithRegionId',
                    cancelToken: new CancelTokenPipeline(function executor(c) {
                        cancel = c;
                    }),
                    params:{filter_name:search, ...this.oppParams}
                }).then( response => {
                    this.optionsSMU = response.data.data
                    this.loadingSMU = false;
                })

            },

        },
        computed: {
            call_params() {
                return {
                    ...this.topParams,
                    opportunity_name: this.param_pipeline_name
                }
            },
            param_pipeline_name() {
                if (this.searchInput !== '') { return this.searchInput }
                else { return null }
            },
            user(){ 
                return state.user 
            },
            params(){ 
                return state.globalParams 
            },
            oppParams() {
                let param = this.params
                if (this.$route.params.catId) {
                    param =  {...state.globalParams, ...{sectorAmbiciona: this.$route.params.catId}, ...this.topParams }
                }
                return param
            },
            legendColors(){ 
                return this.$legendColorsBySector(this.sectors) 
            },
            list() {
                let i = 0
                let list = this.OpportunitiesList.opportunities || []
                if (this.probavilityFilter && this.probavilityFilter.length) {
                    list = this._.filter(list, (p) => {
                        return this._.includes(this.probavilityFilter, p.probability)
                    })
                }
                if (this.statusFilter && this.statusFilter.length) {
                    list = this._.filter(list, (p) => {
                        return this._.includes(this.statusFilter, p.status)
                    })
                }
                if (this.sectorFilter) {
                    list = this._.filter(list, (p) => {
                        return this._.includes(this.sectorFilter, p.sector_ambiciona)
                    })
                }
                if (this.smuFilter) {
                    list = this._.filter(list, (p) => {
                        return this._.includes(this.smuFilter, p.competency)
                    })
                }
                this.numberOfOpportunities = list.length
                return this._.slice(list, 0, this.itemsPerPage * this.page)
            },
            probabilityParams() {
                if (this.probavilityFilter.length > 0) {
                    const result = []
                    for (let index = 0; index < this.probavilityFilter.length; index++) {
                        result.push(this.probavilityFilter[index].filter_id)
                    }
                    return result
                }
            },
            sectorParams() {

                let result = []

                if(state.globalParams.sector){

                    result = state.globalParams.sector

                } else if (this.sectorSelected.length > 0) {

                        for (let index = 0; index < this.sectorSelected.length; index++) {
                            result.push(this.sectorSelected[index].filter_id)
                        }

                    }

                return result

            },
            smuParams() {
            
                let result = []

                if(state.globalParams.competencyIdWithRegionId){

                    result = state.globalParams.competencyIdWithRegionId
                    // this.getSMU();

                } else if (this.smuSelected.length > 0) {

                    for (let index = 0; index < this.smuSelected.length; index++) {
                        result.push(this.smuSelected[index].filter_id)
                    }

                }

                return result

            },
            statusParams() {
                if (this.statusFilter.length > 0) {
                    const result = []
                    for (let index = 0; index < this.statusFilter.length; index++) {
                        result.push(this.statusFilter[index].filter_id)
                    }
                    return result
                }
            },
            topParams() {
                return {
                    ...state.globalParams,
                    probability: this.probabilityParams,
                    status: this.statusParams,
                    competencyIdWithRegionId: this.smuParams,
                    sector: this.sectorParams
                }
            },
            ifdisabledSMU(){
                return !!state.globalParams.competency;
            },
            ifdisabledSector(){
                return !!state.globalParams.sector;
            }

        },
        watch: {
            param_pipeline_name(){
                this.preApiCall() 
            },
            params(n, o) {
                this.page = 1
                this.getServiceLine()
                this.preApiCall()
            },
            topParams(n, o) {
                this.page = 1
                this.getServiceLine()
                this.preApiCall()
                this.getFilterOpportunities()
            },
            selectedOportunity(n,o){
                if(n === false){
                    this.commentary = ''
                } else {

                    this.getComments(n)

                }
            },
            smuSelected(n,o){
                let array = []

                for (let index = 0; index < n.length; index++) {

                    array.push(n[index].filter_id)

                }

                this.axios({
                    method: "get",
                    url: "pipeline/opportunities",
                    cancelToken: new CancelTokenPipeline(function executor(c) {
                        cancel = c;
                    }),
                    params: {
                        ...this.call_params,
                        page: this.page 
                    }
                }).then(response => {
                    if (this.page === 1) {
                        this.OpportunitiesList = response.data
                    }
                    else {
                        this.OpportunitiesList.meta = response.data.meta;
                        for (let index = 0; index < response.data.data.length; index++) {
                            this.OpportunitiesList.data.push(response.data.data[index])
                        }
                    }
                    this.loading = false
                    this.oportunitiesLoading = false
                }).catch(err => {
                    this.loading = false
                    this.oportunitiesLoading = false
                    this.results = err.message;
                })

                

            },
            page(){
                this.preApiCall()
            },

        },
        
        mounted() {
            this.getServiceLine()
            this.preApiCall();
            this.getFilterOpportunities();

        }
    }
</script>

<style>

    .multiselect--disabled .multiselect__select{
        background: transparent !important;
        color: transparent !important
    }

    .multiselect--disabled{
        background: transparent !important;
    }

</style>